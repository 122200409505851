<template>
  <div class="">
    <!-- Row title -->
    <v-row>
      <v-col cols="12" md="12" class="">
        <div class="header-inbox-all" id="tabs">
          <v-chip-group column active-class="blue--text">
            <!-- 'Inbox',
                'Favourite',
                'Archived',
                'Sent',
                'Draft', -->
            <div>
              <v-chip
                class="ma-2"
                color="#EEF3FF"
                style="color: #7297ff"
                :class="filter == '' ? 'active-chip' : ''"
                @click="getAllMessage(1, '')"
                >Inbox</v-chip
              >
            </div>
            <div>
              <v-chip
                class="ma-2"
                color="#EEF3FF"
                style="color: #7297ff"
                :class="filter == 'favourite' ? 'active-chip' : ''"
                @click="getAllMessage(1, 'favourite')"
                >Favourite</v-chip
              >
            </div>
            <div>
              <v-chip
                class="ma-2"
                color="#EEF3FF"
                style="color: #7297ff"
                :class="filter == 'arcived' ? 'active-chip' : ''"
                @click="getAllMessage(1, 'arcived')"
                >Archived</v-chip
              >
            </div>
            <div>
              <v-chip
                class="ma-2"
                color="#EEF3FF"
                style="color: #7297ff"
                :class="filter == 'sent' ? 'active-chip' : ''"
                @click="getAllMessage(1, 'sent')"
                >Sent</v-chip
              >
            </div>
            <div>
              <v-chip
                class="ma-2"
                color="#EEF3FF"
                style="color: #7297ff"
                :class="filter == 'draft' ? 'active-chip' : ''"
                @click="getAllMessage(1, 'draft')"
                >Draft</v-chip
              >
            </div>
          </v-chip-group>
          <!-- <p
            class="main-color value"
            style="cursor: pointer"
            id="all"
            @click="getAllMessage(1, '')"
          >
            {{ $t("All Inbox") }}
          </p>
          <p
            class="value"
            id="favourite"
            @click="getAllMessage(1, 'favourite')"
          >
            {{ $t("Favourites") }}
          </p>
          <p
            class="value arcivedfilter"
            id="arcivedfilter"
            @click="getAllMessage(1, 'arcived')"
          >
            {{ $t("Archived") }}
          </p>
          <p class="value" id="send" @click="getAllMessage(1, 'sent')">
            {{ $t("Sent") }}
          </p>
          <p class="value" id="draft" @click="getAllMessage(1, 'draft')">
            {{ $t("Drafts") }}
          </p> -->
        </div>
      </v-col>
    </v-row>

    <!-- Row Header -->
    <v-row class="">
      <v-col cols="12" lg="4" md="4" sm="2">
        <div class="mt-3 px-5">
          <v-checkbox
            v-model="selectedAll"
            style="margin: 0px; padding: 0px"
            hide-details
            @change="selectAllmessages"
            v-if="filter != 'sent' && filter != 'draft'"
          />
        </div>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="2">
        <div class="center" v-if="filter != 'sent' && filter != 'draft'">
          <v-icon
            style="opacity: 0.6; cursor: pointer"
            :title="$t('Read')"
            @click="messagebulkStatus('read', 1)"
            large
            >drafts</v-icon
          ><v-icon
            class="main-color"
            style="cursor: pointer"
            @click="messagebulkStatus('read', 0)"
            :title="$t('Unread')"
            large
            >email</v-icon
          >
          <v-icon
            style="opacity: 0.6; cursor: pointer; color: #f4b212"
            :title="$t('Favourite')"
            @click="messagebulkStatus('favourite', 1)"
            large
            >star</v-icon
          >

          <v-icon
            style="opacity: 0.6; cursor: pointer"
            :title="$t('Unfavourite')"
            @click="messagebulkStatus('favourite', 0)"
            large
            >star_outline</v-icon
          >
          <v-icon
            v-if="filter == 'arcived'"
            class="main-color"
            style="cursor: pointer"
            @click="messagebulkStatus('arcived', 0)"
            :title="$t('Unarchive')"
            large
            >archive</v-icon
          >
          <v-icon
            v-else
            style="opacity: 0.6; cursor: pointer"
            :title="$t('Archive')"
            @click="messagebulkStatus('arcived', 1)"
            large
            >archive</v-icon
          >
        </div>
      </v-col>
      <v-col cols="12" lg="4" md="4">
        <div class="right">
          <label class="search" for="inpt_search">
            <input
              id="inpt_search"
              class="input_search"
              @focus="inputFocus"
              @blur="inputBlur"
              type="text"
              v-model="search"
            />
          </label>
          <v-menu
            v-model="filterMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                :title="$t('Search')"
                large
                color="#7297ff"
                @click="initializeDatePicker"
                >mdi-filter</v-icon
              >
            </template>

            <v-card class="py-4">
              <v-list>
                <v-list-item>
                  <v-text-field
                    :label="$i18n.t('Date')"
                    id="date"
                    solo
                    v-model="date"
                  ></v-text-field>
                </v-list-item>
              </v-list>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="primary" text @click="filterMenu = false">{{
                  $t("Close")
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <router-link :to="'/inbox/create'" class="mx-3">
            <v-icon large class="main-color">add_circle</v-icon></router-link
          >
        </div>
      </v-col>
    </v-row>

    <!-- preloader -->
    <div class="preloader" style="text-align: center" v-if="loader">
      <img
        src="../../assets/img/preloader.gif"
        id="preloader-custom"
        alt="preloader"
        class
      />
    </div>
    <!-- Row Message -->
    <v-row>
      <v-col cols="12" md="12">
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <!-- <td style="padding: 0 20px; width: 44px">
                  
                </td> -->
                <td
                  :class="
                    item.read == 1 ? 'read  responsive-sm' : ' responsive-sm'
                  "
                >
                  <div class="content d-flex align-center px-2 py-3">
                    <div class="">
                      <v-checkbox
                        v-model="selected"
                        :value="item.id"
                        class="checkbox"
                        style="margin: 0px; padding: 0px"
                        hide-details
                        v-if="filter != 'sent' && filter != 'draft'"
                      />
                    </div>

                    <div
                      @click="goToView(item, item.id)"
                      class="pointable px-2"
                    >
                      <v-avatar tile class="avatar-rounder" size="60">
                        <img
                          v-if="item.user_logo == null"
                          id="logo"
                          src="../../assets/avatar.png"
                        />
                        <img v-else :src="item.user_logo" alt="John" />
                      </v-avatar>
                    </div>

                    <div
                      @click="goToView(item, item.id)"
                      class="pointable px-2"
                    >
                      <div
                        :class="item.read == 0 ? 'username' : ''"
                        style="margin-bottom: 0"
                      >
                        {{ item.created_by }}
                      </div>
                      <v-list-item-title
                        style="margin-bottom: 0"
                        class="subject"
                      >
                        {{ item.subject }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >{{ item.message }}
                        <v-icon
                          v-if="item.attatched"
                          style="font-size: 14px; transform: rotate(90deg)"
                          >attach_file</v-icon
                        >
                      </v-list-item-subtitle>
                    </div>
                  </div>
                </td>
                <td class="hidden-sm">
                  <div class="text-right">
                    <p>{{ item.date }}</p>
                  </div>
                  <div
                    class="text-right"
                    v-if="filter != 'sent' && filter != 'draft'"
                  >
                    <v-icon
                      v-if="!item.read"
                      class="main-color"
                      style="cursor: pointer"
                      @click="messageChangStatus('read', 1, item.id, index)"
                      :title="$t('Read')"
                      >email</v-icon
                    >
                    <v-icon
                      @click="messageChangStatus('read', 0, item.id, index)"
                      v-else
                      style="opacity: 0.6"
                      :title="$t('Unread')"
                      >drafts</v-icon
                    >
                    <v-icon
                      v-if="item.arcived"
                      class="main-color"
                      style="cursor: pointer"
                      @click="messageChangStatus('arcived', 0, item.id, index)"
                      :title="$t('Unarchive')"
                      >archive</v-icon
                    >

                    <v-icon
                      v-else
                      @click="messageChangStatus('arcived', 1, item.id, index)"
                      style="cursor: pointer; opacity: 0.6"
                      :title="$t('Archive')"
                      >archive</v-icon
                    >
                    <v-icon
                      v-if="item.favourite"
                      style="cursor: pointer; color: #f4b212"
                      :title="$t('Unfavourite')"
                      @click="
                        messageChangStatus('favourite', 0, item.id, index)
                      "
                      >star</v-icon
                    >
                    <v-icon
                      v-else
                      @click="
                        messageChangStatus('favourite', 1, item.id, index)
                      "
                      style="cursor: pointer"
                      :title="$t('Favourite')"
                      >star_outline</v-icon
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <!-- ======================================== Pagination ======================================  -->
    <div class="text-center pt-2">
      <v-col cols="12">
        <center>
          <a
            @click="showMorePosts"
            v-if="page < length"
            style="color: #7297ff; text-align: center"
            >{{ $t("show more") }}</a
          >
        </center>
      </v-col>
      <v-snackbar
        v-model="snack"
        :timeout="snackTime"
        :color="snackColor"
        :right="true"
        :top="true"
      >
        <!-- {{ snackText }} -->

        <span class="white--text">{{ snackText }}</span>

        <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
      </v-snackbar>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Inbox",

  data() {
    return {
      filterMenu: false,
      date: "",

      options: {
        itemsPerPage: 5,
      },
      loader: false,
      snack: "",
      acctiveClass: "",
      dialogReset: false,
      snackTime: 3000,
      snackColor: "",
      snackText: "",
      filterMenu: false,
      loading: true,
      selected: [],
      selectedAll: false,
      items: [],
      search: "",
      page: 1,
      send: false,
      length: "",
      totalVisible: "",
      totalItems: "",
      editedIndex: -1,
      filter: "",
    };
  },
  watch: {
    search: {
      handler() {
        // console.log(this.search);
        this.getAllMessage(1, this.filter);
      },
      deep: true,
    },
    date: {
      handler() {
        this.getAllMessage(1, this.filter);
      },
      deep: true,
    },
  },
  methods: {
    initializeDatePicker() {
      let _this = this;

      setTimeout(() => {
        $("#date").calendarsPicker({
          maxDate: 0,
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._month + "/" + date[0]._day + "/" + date[0]._year;
              _this.date = dateH;
            } else {
              _this.date = "";
            }
          },
        });
      }, 200);
    },
    messagebulkStatus(status, value) {
      let data = [status, value];
      if (this.selected.length > 0) {
        axios
          .post(
            this.getApiUrl + "/inbox/messagebulkStatus",
            { data: data, ids: this.selected },

            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            // console.log(response);
            this.selected = [];
            this.selectedAll = false;
            this.getAllMessage(1, this.filter);
            // $("#all")
            //   .addClass("main-color")
            //   .siblings()
            //   .removeClass("main-color");
          });
      }
    },
    selectAllmessages() {
      let _this = this;
      if (this.selectedAll) {
        this.items.forEach((item) => {
          _this.selected.push(item.id);
        });
      } else {
        this.items.forEach((item) => {
          _this.selected.splice(_this.selected.indexOf(item.id), 1);
        });
      }
    },
    showMorePosts() {
      this.getAllMessage(null, this.filter);
    },

    getAllMessage(page = 1, filter = "") {
      this.filter = filter;
      let getAllMessage = true;
      if (page != null && this.search == "") {
        this.page = page;
      } else if (this.search != "") {
        this.page = 1;
      } else {
        if (this.page == this.length) {
          getAllMessage = false;
        } else {
          if (this.page < this.length) {
            this.page = this.page + 1;
          } else {
            this.page = 1;
          }
        }
      }
      if (getAllMessage) {
        this.loader = true;
        axios
          .get(
            this.getApiUrl +
              `/inbox/${filter}?page=` +
              this.page +
              "&search=" +
              this.search +
              "&date=" +
              this.date,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (this.search != "") {
              this.items = response.data.data.data;
            } else {
              // console.log(response);
              this.items.push(...response.data.data.data);
            }
            if (this.page == 1) {
              this.items = response.data.data.data;
            }

            this.page = response.data.data.current_page;
            this.length = response.data.data.last_page;
            this.selected = [];
            this.selectedAll = false;
            this.loader = false;
          });
      }
      this.loader = false;
    },
    goToView(item, id) {
      if (item.draft) {
        this.$router.push(
          {
            path: "/inbox/create?id=" + id,
          },
          () => {}
        );
      } else {
        this.$router.push(
          {
            path: "/inbox/show/" + id,
          },
          () => {}
        );
      }
    },
    messageChangStatus(status, value, id, index) {
      let data = [status, value];
      axios
        .post(
          this.getApiUrl + "/inbox/changeStatus/" + id,
          data,

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          // console.log(response);

          if (status == "arcived") {
            if (this.filter != "favourite" && this.filter != "draft") {
              this.items.splice(index, 1);
            } else {
              this.items[index][status] = value ? true : false;
            }
          } else {
            if (this.filter == "favourite") {
              if (status == "favourite" && value == 0) {
                this.items.splice(index, 1);
              }
            }
            this.items[index][status] = value ? true : false;
          }

          this.selected = [];
          this.selectedAll = false;
        });
    },
    inputFocus() {
      $("#inpt_search").parent("label").addClass("active");
      $(".input_search").parent("label").addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search").parent("label").removeClass("active");
        $(".input_search").parent("label").removeClass("active");
      }
    },
    setPaginationParameters(response) {
      this.length = response.data.data.last_page;
      if (this.length > 5) this.totalVisible = 5;
    },
  },
  mounted() {
    this.initializeDatePicker();
    this.getAllMessage();
    $("#tabs .value").on("click", function () {
      $(this).addClass("main-color").siblings().removeClass("main-color");
    });
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

#wrapper {
  transition: all 0.4s ease 0s;
}

.main-color {
  color: $main-color;
}
.headerTable-div {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;

  .custom-header {
    margin-bottom: 0;
  }
}

.center {
  text-align: center !important;
}
.right {
  text-align: right !important;
}

.select {
  margin-top: 20px !important;
}
.header-inbox-all h1,
.header-inbox-all p {
  display: inline;
  margin-right: 10px;
}
.header-inbox-all p {
  cursor: pointer;
}
.text-left {
  text-align: left !important;
}
.read {
  opacity: 0.7 !important;
}
.username {
  color: #7297ff;
}
.subject {
  font-size: 19px;
}
// #unarcive {
//   display: none;
// }
</style>
